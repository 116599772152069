import React from 'react';
import {
  IResourceComponentsProps,
  BaseRecord,
  GetListResponse,
} from '@refinedev/core';
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  TextField,
  DateField,
} from '@refinedev/antd';
import { Table, Space, Row } from 'antd';
import { HyperLinkField } from '../../components/table';
import { DOWNLOAD_CSV_SAMPLE_LINK } from '../../constants';

export const OtaRewardBatchList: React.FC<
  // eslint-disable-next-line @typescript-eslint/ban-types
  IResourceComponentsProps<GetListResponse<{}>>
> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  return (
    <List>
      <Row align="stretch">
        <HyperLinkField
          value="CSV Sample Data File"
          link={DOWNLOAD_CSV_SAMPLE_LINK}
        />
      </Row>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex={['status']} title="Status" />
        <Table.Column
          dataIndex={['createdBy']}
          title="Created By"
          render={(value: any) => <TextField value={value?.email} />}
        />
        <Table.Column
          dataIndex={['reviewedBy']}
          title="Reviewed By"
          render={(value: any) => <TextField value={value?.email} />}
        />
        <Table.Column
          dataIndex={['createdAt']}
          title="Created At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

import { Tag } from 'antd';

interface HyperLinkFieldProps {
  link: string;
  value: string;
  color?: string;
}

export const HyperLinkField: React.FC<HyperLinkFieldProps> = (
  props: HyperLinkFieldProps,
) => {
  return (
    <Tag color={props.color}>
      <a target="_blank" href={props.link + props.value}>
        {props.value}
      </a>
    </Tag>
  );
};

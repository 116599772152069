import lookup from 'country-code-lookup';

export const DEFAULT_TIMEZONE = 'Asia/Ho_Chi_Minh';

interface StatusKyc {
  [key: string]: string;
}

interface SelectOption {
  label: string;
  value: string;
}

interface SelectReasonOption {
  label: string;
  value: string;
}

export const HYPERVERGE_STATUS_COLOR: StatusKyc = {
  auto_approved: 'green',
  manually_approved: 'green',
  auto_declined: 'red',
  manually_declined: 'red',
  needs_review: 'blue',
};

export const HYPERVERGE_STATUS: SelectOption[] = [
  {
    label: 'Auto approved',
    value: 'auto_approved',
  },
  {
    label: 'Manually approved',
    value: 'manually_approved',
  },
  {
    label: 'Auto declined',
    value: 'auto_declined',
  },
  {
    label: 'Needs review',
    value: 'needs_review',
  },
];

export const IMOTA_KYC2_STATUS_COLOR: StatusKyc = {
  processing: 'blue',
  accepted: 'green',
  rejected: 'red',
};

export const IMOTA_KYC2_STATUS: SelectOption[] = [
  {
    label: 'Processing',
    value: 'processing',
  },
  {
    label: 'Accepted',
    value: 'accepted',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
];

type ReasonMessageLanguage = {
  [key: string]: {
    vn: string;
    en: string;
  };
};

export const REASON_LIST: ReasonMessageLanguage = {
  fraudulent: {
    vn: 'Tài liệu không hợp lệ. Chúng tôi nghi ngờ tài liệu mà bạn đã cung cấp có thể là giả mạo. Chúng tôi xin lỗi vì sự bất tiện này, vui lòng cung cấp một tài liệu hợp lệ, hoặc liên hệ với bộ phận Chăm sóc khách hàng của Imota để được hỗ trợ thêm.',
    en: 'This document is not accepted. We suspect that the document you provided may be fraudulent. We apologize for the inconvenience, please resubmit a valid document to complete the process, or contact our customer support team for further assistance.',
  },
  existed: {
    vn: 'Thông tin định danh đã tồn tại. Chúng tôi xin lỗi vì sự bất tiện này, vui lòng liên hệ với bộ phận Chăm sóc khách hàng của Imota để được hỗ trợ thêm.',
    en: 'This identity has already existed. We apologize for the inconvenience, please contact our customer support team for further assistance.',
  },
  rejected: {
    vn: 'Tài liệu KYC của bạn đã bị từ chối, vui lòng liên hệ chăm sóc khách hàng để được hỗ trợ.',
    en: 'Your KYC document has been declined, please contact customer care for assistance.',
  },
  needReview: {
    vn: 'Tài liệu KYC của bạn đang được tiến hành xác thực, vui lòng liên hệ chăm sóc khách hàng để được hỗ trợ.',
    en: 'Your KYC documents are being validated, please contact customer care for assistance.',
  },
  notMatchSelfie: {
    vn: 'Chúng tôi không thể xác minh được danh tính của bạn do ảnh chụp và tài liệu không trùng khớp. Vui lòng thử lại với các tài liệu chính xác và mới nhất.',
    en: 'We were unable to verify your identity due to a mismatch between the selfie and document you provided. Please try again with accurate and updated documents.',
  },
  unsupported: {
    vn: 'Tài liệu định danh này chưa được hỗ trợ. Chúng tôi xin lỗi vì sự bất tiện này, bạn vui lòng gửi một tài liệu phù hợp với danh sách tài liệu định danh được hỗ trợ.',
    en: 'Unsupported document. We apologize for the inconvenience, please submit a valid document that meets our requirements.',
  },
};

export const REASON_MESSAGE_LANGUAGE: SelectReasonOption[] = [
  {
    label: 'Fraudulent',
    value: 'fraudulent',
  },
  {
    label: 'Existed',
    value: 'existed',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
  {
    label: 'Need Review',
    value: 'needReview',
  },
  {
    label: 'Not Match Selfie',
    value: 'notMatchSelfie',
  },
  {
    label: 'Unsupported',
    value: 'unsupported',
  },
];

export const HYPERVERGE_LINK =
  'https://dashboard.hyperverge.co/record?transactionId=';

export const USERS_LINK = '/users/show/';

export enum DocumentType {
  ID_CARD = 'idCard',
  PASSPORT = 'passport',
  DL = 'driverLicense',
}

export const DOCUMENT_TYPE_OPTION: SelectOption[] = [
  {
    label: 'CMND/CCCD/IDCard',
    value: 'idCard',
  },
  {
    label: 'Passport',
    value: 'passport',
  },
  {
    label: 'Driver License',
    value: 'driverLicense',
  },
];

export const GENDER_OPTION: SelectOption[] = [
  {
    label: 'Male',
    value: 'M',
  },
  {
    label: 'Female',
    value: 'F',
  },
];

export const BOOLEAN_OPTION: SelectOption[] = [
  {
    label: 'True',
    value: 'true',
  },
  {
    label: 'False',
    value: 'false',
  },
];

export const EARN_CLAIM_HISTORY_STATUS: SelectOption[] = [
  {
    label: 'New',
    value: 'new',
  },
  {
    label: 'Approved',
    value: 'approved',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
];

export const EARN_CAMPAIGN_TYPE_OPTION = {
  options: [
    {
      label: 'cpa',
      value: 'cpa',
    },
    {
      label: 'cps',
      value: 'cps',
    },
    {
      label: 'cpi',
      value: 'cpi',
    },
    {
      label: 'cpl',
      value: 'cpl',
    },
    {
      label: 'cpql',
      value: 'cpql',
    },
    {
      label: 'd2c',
      value: 'd2c',
    },
    {
      label: 'mix',
      value: 'mix',
    },
  ],
};

export const EARN_CAMPAIGN_CURRENCY_UNIT_OPTION = {
  options: [
    {
      label: 'vnd',
      value: 'vnd',
    },
    {
      label: 'usd',
      value: 'usd',
    },
    {
      label: 'usdt',
      value: 'usdt',
    },
  ],
};

export const EARN_CAMPAIGN_PLATFORM_OPTION = {
  options: [
    {
      label: 'all',
      value: 'all',
    },
    {
      label: 'android',
      value: 'android',
    },
    {
      label: 'ios',
      value: 'ios',
    },
    {
      label: 'web',
      value: 'web',
    },
  ],
};

export const CAMPAIGN_REWARD_TYPE_OPTION = {
  options: [
    {
      label: 'red',
      value: 'red',
    },
    {
      label: 'green',
      value: 'green',
    },
  ],
};

export const EARN_USER_HISTORY_STATE_CAMPAIGN_STATUS: SelectOption[] = [
  {
    label: 'clicked',
    value: 'clicked',
  },
  {
    label: 'installed',
    value: 'installed',
  },
  {
    label: 'waiting_approval',
    value: 'waiting_approval',
  },
  {
    label: 'approved',
    value: 'approved',
  },
  {
    label: 'rejected',
    value: 'rejected',
  },
];

export const USER_DATA_SEARCH_OPTION: SelectOption[] = [
  {
    label: 'normal',
    value: 'normal',
  },
  {
    label: 'estimate',
    value: 'estimate',
  },
];

export const OTA_REWARD_STATUS: SelectOption[] = [
  {
    label: 'CANCELLED',
    value: 'CANCELLED',
  },
  {
    label: 'APPROVED',
    value: 'APPROVED',
  },
  {
    label: 'COMPLETED',
    value: 'COMPLETED',
  },
];

export const DOWNLOAD_CSV_SAMPLE_LINK =
  'https://drive.google.com/file/d/1JSGkDo6BTFI9z112fCAhqtHqLbuthpTE/view?usp=sharing';

export const NOTIFICATION_PLATFORM_OPTION: SelectOption[] = [
  {
    label: 'all',
    value: 'all',
  },
  {
    label: 'android',
    value: 'android',
  },
  {
    label: 'ios',
    value: 'ios',
  },
  {
    label: 'web',
    value: 'web',
  },
];

export const NOTIFICATION_DEEP_LINK_OPTION: SelectOption[] = [
  {
    label: 'Empty',
    value: 'empty',
  },
  {
    label: 'Web',
    value: 'web',
  },
  {
    label: 'Otara Mining',
    value: 'otara-mining',
  },
  {
    label: 'Lucky Wheel',
    value: 'otara-wheel',
  },
];

export const NOTIFICATION_TYPE: Record<string, string> = {
  daily: 'Schedule',
  push_now: 'Push Now',
};

export const NOTIFICATION_TYPE_OPTION: SelectOption[] = [
  {
    label: 'Schedule',
    value: 'daily',
  },
  {
    label: 'Push Now',
    value: 'push_now',
  },
];

export const NOTIFICATION_HOURS_OPTION: SelectOption[] = [
  {
    label: '00',
    value: '0',
  },
  {
    label: '01',
    value: '1',
  },
  {
    label: '02',
    value: '2',
  },
  {
    label: '03',
    value: '3',
  },
  {
    label: '04',
    value: '4',
  },
  {
    label: '05',
    value: '5',
  },
  {
    label: '06',
    value: '6',
  },
  {
    label: '07',
    value: '7',
  },
  {
    label: '08',
    value: '8',
  },
  {
    label: '09',
    value: '9',
  },
  {
    label: '10',
    value: '10',
  },
  {
    label: '11',
    value: '11',
  },
  {
    label: '12',
    value: '12',
  },
  {
    label: '13',
    value: '13',
  },
  {
    label: '14',
    value: '14',
  },
  {
    label: '15',
    value: '15',
  },
  {
    label: '16',
    value: '16',
  },
  {
    label: '17',
    value: '17',
  },
  {
    label: '18',
    value: '18',
  },
  {
    label: '19',
    value: '19',
  },
  {
    label: '20',
    value: '20',
  },
  {
    label: '21',
    value: '21',
  },
  {
    label: '22',
    value: '22',
  },
  {
    label: '23',
    value: '23',
  },
];

export const NOTIFICATION_MINUTE_OPTION: SelectOption[] = [
  {
    label: '00',
    value: '0',
  },
  {
    label: '30',
    value: '30',
  },
];

const selectOptionCountries = () => {
  const allCountries = lookup.countries;
  return allCountries.map((i: any) => {
    return {
      label: i.country,
      value: i.iso2,
    };
  });
};

export const COUNTRY_CODE_OPTION: SelectOption[] = selectOptionCountries();
